import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import store from '../store.js'; // Importa o store

const routes = [
  {
    path: '/chat/:conversaId?',
    name: 'chat',
    component: () => import(/* webpackChunkName: "chat" */ '../views/ChatView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Chat', title: 'Conversas' }
  },
  {
    path: '/login/:logout?',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: { breadcrumb: 'Login', title: 'Login' }
  },
  {
    path: '/register', // Adiciona o parâmetro opcional
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/RegisterView.vue'),
    meta: { breadcrumb: 'Register', title: 'Register' }
  },
  {
    path: '/novo/:slug?', // Adiciona o parâmetro opcional
    name: 'novo',
    component: () => import(/* webpackChunkName: "register_revenda" */ '../views/RegisterView.vue'),
    meta: { breadcrumb: 'Novo', title: 'Novo' }
  },
  {
    path: '/configurar',
    name: 'configurar',
    component: () => import(/* webpackChunkName: "configurar" */ '../views/ConfigView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Configurar', title: 'Configurar' }
  },
  {
    path: '/integracoes',
    name: 'integracoes',
    component: () => import(/* webpackChunkName: "integracoes" */ '../views/IntegracoesView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Canais de atendimento / Integrações', title: 'Canais de atendimento / Integrações' }
  },
  {
    path: '/admin/enviar-mensagem',
    name: 'enviar-mensagem',
    component: () => import(/* webpackChunkName: "enviar-mensagem" */ '../views/EnviarMensagemView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Enviar Mensagem', title: 'Enviar Mensagem' }
  },
  {
    path: '/primeiros-passos',
    name: 'primeiros-passos',
    component: () => import(/* webpackChunkName: "primeiros-passos" */ '../views/PrimeirosPassosView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Primeiros Passos', title: 'Primeiros Passos' }
  },
  {
    path: '/prompts',
    name: 'prompts',
    component: () => import(/* webpackChunkName: "prompts" */ '../views/ListarPromptsView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Prompts', title: 'Prompts' }
  },
  {
    path: '/baseConhecimento/novo/:idIntegracao/:nomeServico',
    name: 'nova-base-conhecimento-integracao',
    component: () => import(/* webpackChunkName: "nova-base-conhecimento" */ '../views/PromptView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Nova Base de Conhecimento', title: 'Nova Base de Conhecimento' }
  },
  {
    path: '/baseConhecimento/novo/',
    name: 'nova-base-conhecimento',
    component: () => import(/* webpackChunkName: "nova-base-conhecimento" */ '../views/PromptView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Nova Base de Conhecimento', title: 'Nova Base de Conhecimento' }
  },
  {
    path: '/baseConhecimento/editar/:idPrompt',
    name: 'editar-base-conhecimento',
    component: () => import(/* webpackChunkName: "editar-base-conhecimento" */ '../views/PromptView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Editar Base de Conhecimento', title: 'Editar Base de Conhecimento' }
  },
  {
    path: '/assinatura',
    name: 'assinatura',
    component: () => import(/* webpackChunkName: "assinatura" */ '../views/AssinaturaView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'assinatura', title: 'Assinatura' }
  },
  {
    path: '/recuperar-senha',
    name: 'recuperar-senha',
    component: () => import(/* webpackChunkName: "recuperar-senha" */ '../views/PasswordRecoveryView.vue'),
    meta: { breadcrumb: 'Recuperar Senha', title: 'Recuperar Senha' }
  },
  {
    path: '/meus-dados',
    name: 'meus-dados',
    component: () => import(/* webpackChunkName: "meus-dados" */ '../views/MeusDadosView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Meus Dados', title: 'Meus Dados' }
  },
  {
    path: '/listar-tags',
    name: 'listar-tags',
    component: () => import(/* webpackChunkName: "listar-tags" */ '../views/ListarTags.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Listar Tags', title: 'Listar Tags' }
  },
  {
    path: '/disparo-em-massa',
    name: 'disparo-em-massa',
    component: () => import(/* webpackChunkName: "disparo-em-massa" */ '../views/DisparoMensagensEmMassaView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Envio de mensagens em massa', title: 'Envio de mensagens' }
  },
  {
    path: '/disparo-em-massa/editar/:idEnvioMensagem',
    name: 'editar-disparo-em-massa',
    component: () => import(/* webpackChunkName: "disparo-em-massa" */ '../views/CriarEditarDisparoMensagensView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Editar envio de mensagens', title: 'Editar envio de mensagens' }
  },
  {
    path: '/disparo-em-massa/novo',
    name: 'novo-disparo-em-massa',
    component: () => import(/* webpackChunkName: "disparo-em-massa" */ '../views/CriarEditarDisparoMensagensView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Novo envio de mensagens', title: 'Novo envio de mensagens' }
  },
  {
    path: '/historico-pagamento',
    name: 'historico-pagamento',
    component: () => import(/* webpackChunkName: "historico-pagamento" */ '../views/HistoricoPagamentoView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Histórico de Pagamentos e Recargas', title: 'Histórico de Pagamentos e Recargas' }
  },
  {
    path: '/webhook-url',
    name: 'webhook-url',
    component: () => import(/* webpackChunkName: "webhook-url" */ '../views/UserWebhookUrlView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Configurar API/Webhook', title: 'Configurar API/Webhook' }
  },
  {
    path: '/listar-atendentes',
    name: 'listar-atendentes',
    component: () => import(/* webpackChunkName: "listar-atendentes" */ '../views/ListarAtendentesView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Listar Atendentes', title: 'Listar Atendentes' }
  },
  {
    path: '/listar-departamentos',
    name: 'listar-departamentos',
    component: () => import(/* webpackChunkName: "listar-departamentos" */ '../views/ListarDepartamentosView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Listar Departamentos', title: 'Listar Departamentos' }
  },
  {
    path: '/listar-bases-conhecimento',
    name: 'listar-bases-conhecimento',
    component: () => import(/* webpackChunkName: "listar-bases-conhecimento" */ '../views/ListarBasesConhecimentoView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Listar Bases de Conhecimento', title: 'Listar Bases de Conhecimento' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFoundView.vue'),
    meta: { title: 'Página não encontrada' }
  },
  {
    path: '/planos',
    name: 'planos',
    component: () => import(/* webpackChunkName: "planos" */ '../views/AssinaturaView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Planos', title: 'Planos' }
  },
  {
    path: '/recuperacao-de-conversas/editar/:idRecuperacao',
    name: 'editar-recuperacao-de-conversas',
    component: () => import(/* webpackChunkName: "recuperacao-de-conversas" */ '../views/CriarEditarRecuperacaoConversasView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Editar Recuperação de conversas', title: 'Editar Recuperação de conversas' }
  },
  {
    path: '/recuperacao-de-conversas/novo/',
    name: 'nova-recuperacao-de-conversas',
    component: () => import(/* webpackChunkName: "recuperacao-de-conversas" */ '../views/CriarEditarRecuperacaoConversasView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Recuperação de conversas', title: 'Recuperação de conversas' }
  },
  {
    path: '/listar-recuperacao-conversas',
    name: 'listar-recuperacao-conversas',
    component: () => import(/* webpackChunkName: "recuperacao-de-conversas" */ '../views/ListarRecuperacaoConversasView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Listar Recuperação de conversas', title: 'Listar Recuperação de conversas' }
  },
  {
    path: '/kanban',
    name: 'Kanban',
    component: () => import(/* webpackChunkName: "Kanban" */ '../views/KanbanView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Kanban', title: 'Kanban' }
  },
  {
    path: '/contatos',
    name: 'contatos',
    component: () => import(/* webpackChunkName: "contatos" */ '../views/ContatosView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Contatos', title: 'Contatos' }
  },
  {
    path: '/relatorios',
    name: 'relatorios',
    component: () => import(/* webpackChunkName: "relatorios" */ '../views/RelatoriosView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Relatórios', title: 'Relatórios' }
  },
  {
    path: '/google_calendar',
    name: 'google_calendar',
    component: () => import(/* webpackChunkName: "google_calendar" */ '../views/GoogleCalendarView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Google Calendar', title: 'Google Calendar' }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Função genérica para atualizar o título do documento
function updateDocumentTitle(to) {
  document.title = to.meta.title || 'Chat Inteligente';
}

// Adiciona um global before guard para verificar autenticação e atualizar o título do documento
router.beforeEach(async (to, from, next) => {
  updateDocumentTitle(to); // Atualiza o título antes da navegação

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = store.state.token;
  

  if (requiresAuth && !token) {
    return next('/login');
  }
  next(); // Prossegue com a navegação se não for necessário autenticação
  
    
});

// Adiciona um afterEach guard para atualizar os breadcrumbs no store e o título do documento
router.afterEach((to) => {
  store.dispatch('breadcrumb/updateBreadcrumbs', to); // Chama a ação em vez da mutação
  updateDocumentTitle(to); // Atualiza o título após a navegação
});

export default router;
